// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { API_VERSION } from './api-version';
import { STAGING_API_URL } from './staging-api-url';

export const environment = {
  api: STAGING_API_URL,
  production: false,
  frontApp: 'https://staging.my.feragenlab.com',
  refresh: `${STAGING_API_URL}/auth/refresh`,
  activateAccount: `${STAGING_API_URL}/${API_VERSION}/users/activate_account`,
  users: `${STAGING_API_URL}/${API_VERSION}/users`,
  auth: `${STAGING_API_URL}/auth`,
  changePassword: `${STAGING_API_URL}/${API_VERSION}/users/change_password`,
  updateProfile: `${STAGING_API_URL}/${API_VERSION}/users/update_profile`,
  userDetails: `${STAGING_API_URL}/${API_VERSION}/users/user_details`,
  logout: `${STAGING_API_URL}/auth/invalidate_refresh_token`,
  forgottenPassword: `${STAGING_API_URL}/auth/forgotten_password`,
  resetPassword: `${STAGING_API_URL}/auth/reset_password`,
  animals: `${STAGING_API_URL}/${API_VERSION}/animals`,
  breeds: `${STAGING_API_URL}/${API_VERSION}/breeds`,
  breedMixed: `${STAGING_API_URL}/${API_VERSION}/breed-mixed`,
  matching: `${STAGING_API_URL}/${API_VERSION}/matching`,
  diversity: `${STAGING_API_URL}/${API_VERSION}/diversity`,
  diseases: `${STAGING_API_URL}/${API_VERSION}/diseases`,
  traits: `${STAGING_API_URL}/${API_VERSION}/traits`,
  diseaseCategories: `${STAGING_API_URL}/${API_VERSION}/disease_categories`,
  traitCategories: `${STAGING_API_URL}/${API_VERSION}/trait_categories`,
  healthReportAll: `${STAGING_API_URL}/${API_VERSION}/health/report`,
  breedMixReportAll: `${STAGING_API_URL}/${API_VERSION}/breed-mixed/report`,
  breedCertificate: `${STAGING_API_URL}/${API_VERSION}/breed-mixed/certificate`,
  isagReports: `${STAGING_API_URL}/${API_VERSION}/isag/report`,
  diversityReports: `${STAGING_API_URL}/${API_VERSION}/diversity/report`,
  dlaReports: `${STAGING_API_URL}/${API_VERSION}/dla/report`,
  breedingClubReport: `${STAGING_API_URL}/${API_VERSION}/breeding-club/report`,
  submissions: `${STAGING_API_URL}/${API_VERSION}/submissions`,
  examinations: `${STAGING_API_URL}/${API_VERSION}/examinations`,
  documents: `${STAGING_API_URL}/${API_VERSION}/documents`,
  events: `${STAGING_API_URL}/${API_VERSION}/events`,
  animalsPaginated: `${STAGING_API_URL}/${API_VERSION}/animals/paginated`,
  guidelines: `${STAGING_API_URL}/${API_VERSION}/guidelines`,
  change_language: `${STAGING_API_URL}/${API_VERSION}/users/change_language`,
  qr_code: `${STAGING_API_URL}/${API_VERSION}/qr_code`,
  qrCodeData: `${STAGING_API_URL}/${API_VERSION}/qr_code/data`,
  change_animals_page_view_type: `${STAGING_API_URL}/${API_VERSION}/users/animals_page_view_type`,
  sendShareLink: `${STAGING_API_URL}/${API_VERSION}/animals/send_share_link`,
  share: `${STAGING_API_URL}/${API_VERSION}/animals/share`,
  editProfileShareIdentifier: `${STAGING_API_URL}/${API_VERSION}/animals/edit_profile_share_identifier`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.



